import { Component } from '@angular/core';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  alphabet = ['p', 'd'];
  game = [];
  previousGame = [];
  gameStarted: boolean;
  solution: number;
  userAnswer: number;
  gameInterval: any;
  initialTime = 20;
  timeLeft: number = this.initialTime;

  newGame() {
    this.gameStarted = true;
    this.timeLeft = this.initialTime;
    this.userAnswer = undefined;
    this.fillGame();
    this.previousGame = cloneDeep(this.game);
    this.solution = this.game.filter(letter => this.isTarget(letter)).length;
    this.gameInterval = setInterval(() => {
      this.timeLeft --;
      if (this.timeLeft === 0) {
        this.obfuscateLetters();
        clearInterval(this.gameInterval);
      }
    }, 1000);
  }

  obfuscateLetters() {
    this.game.forEach((value, index) => {
      this.game[index].character = '#';
    });
  }

  isTarget(letter: any): boolean {
    return letter.character === 'd'
      && (letter.upper + letter.lower === 2);
  }

  fillGame() {
    this.game = [];
    for (let i = 0; i < 60; i++) {
      const upperRandom = Math.floor(Math.random() * 4);
      const lowerRandom = Math.floor(Math.random() * 4);
      const randomIndex = Math.floor(Math.random() * this.alphabet.length);
      this.game.push({
        upper: upperRandom,
        character: this.alphabet[randomIndex],
        lower: lowerRandom,
      });
    }
  }

  endGame() {
    this.gameStarted = false;
    this.game = this.previousGame;
    clearInterval(this.gameInterval);
  }

  renderLines(entry: number): string {
    if (entry <= 0) {
      return '';
    }

    let result = '';
    for (let i = 0; i < entry; i++) {
      result += '|';
    }
    return result;
  }
}
